import React, { Component } from 'react'
 import post from '../params/post'
import { CSVReader } from 'react-papaparse'
 
export default class CSVReaderx extends Component {
  handleOnDrop = async(data) => {
    data.splice(0,1)
    console.log('---------------------------')
    console.log(data)
  
    await Promise.all(
        data.map(async v=>{
            let kepada = v.data[1]
            let dari=v.data[2]
            let alamat = v.data[3]
            let hadir = v.data[4]
            let jumlahhadir=v.data[5]
            let pesan=v.data[6]
            await post(
                ` dari: "${dari}", hadir: "${hadir}", jumlahhadir: "${jumlahhadir}", kepada: "${kepada}", pesan:"${pesan}",alamat: "${alamat}"`
            )
        })
    )
    console.log('---------------------------')
    
  }
 
  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
  }
 
  handleOnRemoveFile = (data) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')
  }
 
  render() {
    return (
      <CSVReader
        onDrop={this.handleOnDrop}
        onError={this.handleOnError}
        addRemoveButton
        removeButtonColor='#659cef'
        onRemoveFile={this.handleOnRemoveFile}
      >
        <span>Drop CSV file here or click to upload.</span>
      </CSVReader>
    )
  }
}